import app, { S as appS } from "./app";
import assets, { S as assetsS } from "./assets";

export interface RootState {
  app: appS;
  assets: assetsS;
}

export default {
  app,
  assets,
};
