import Vue from "vue";
import VueRouter, { RouteConfig, RawLocation } from "vue-router";

const publicPath = "";

export declare interface Paths {
  root: string;
  signUp: string;
  refresh: string;
  reset: string;
}

export const paths: Paths = {
  root: publicPath + "/",
  signUp: publicPath + "/sign-up",
  refresh: publicPath + "/refresh",
  reset: publicPath + "/reset",
};

const routes: Array<RouteConfig> = [
  {
    path: paths.root,
    component: () => import("@/views/SignIn.vue"),
    children: [],
  },
  {
    path: paths.signUp,
    component: () => import("@/views/SignUp.vue"),
    children: [],
  },
  {
    path: paths.refresh,
    component: () => import("@/views/Refresh.vue"),
    children: [],
  },
  {
    path: paths.reset,
    component: () => import("@/views/Reset.vue"),
    children: [],
  },
  {
    path: "*",
    redirect: paths.root,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior: (to, from, savedPosition) => {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
  routes,
});

const VueRouterPush = VueRouter.prototype.push;
const VueRouterReplace = VueRouter.prototype.replace;

VueRouter.prototype.push = function push(location: RawLocation) {
  return (VueRouterPush.call(this, location) as any).catch((err: Error) => err);
};
VueRouter.prototype.replace = function replace(location: RawLocation) {
  return (VueRouterReplace.call(this, location) as any).catch(
    (err: Error) => err
  );
};

Vue.use(VueRouter);
Vue.prototype.$paths = paths;

export default router;
