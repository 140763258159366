import Vue from "vue";
import { message, Modal } from "ant-design-vue";
import "./index.scss";

message.config({
  top: "100px",
  maxCount: 3,
});

Vue.prototype.$message = message;
Vue.prototype.$confirm = Modal.confirm;
Vue.prototype.$info = Modal.info;
