import Cookies from "js-cookie";

const isTest =
  location.href.includes("test.angrymiao.com") ||
  !location.href.includes(".angrymiao.com");
const prefix = isTest ? "angrymiao-test-" : "angrymiao-";
const domain =
  window.location.hostname === "localhost" ? "localhost" : "angrymiao.com";

const getItem = (key: string) => {
  return Cookies.get(key, { domain });
};

const setItem = (key: string, value: string, expires: number) => {
  Cookies.remove(key, { domain });
  return value && Cookies.set(key, value, { expires, domain });
};

function getToken() {
  return getItem(`${prefix}token`) || "";
}

function getRefreshToken() {
  return getItem(`${prefix}refreshToken`) || "";
}

function setToken(token: string) {
  setItem(`${prefix}token`, token, 1);
}

function setRefreshToken(token: string) {
  setItem(`${prefix}refreshToken`, token, 30);
}

function signIn({
  token,
  refreshToken,
}: {
  token: string;
  refreshToken: string;
}) {
  setToken(token);
  setRefreshToken(refreshToken);
}

function signOut({ token = "", refreshToken = "" } = {}) {
  setToken(token);
  setRefreshToken(refreshToken);
}

function isAuth() {
  return getToken();
}

export default {
  getRefreshToken,
  setRefreshToken,
  getToken,
  setToken,
  signIn,
  signOut,
  isAuth,
};
