import store, { Oauth2Channel } from "@/store";

const appSetLanguage = (language: string) => {
  return store.dispatch("app/setLanguage", language);
};

const appCheckMobile = () => {
  return store.dispatch("app/checkMobile");
};

const appGetOauth2Url = (channel: Oauth2Channel) => {
  return store.dispatch("app/getOauth2Url", channel);
};

const appOauth2SignIn = () => {
  return store.dispatch("app/oauth2SignIn");
};

const appSendSMS = (phone: string) => {
  return store.dispatch("app/sendSMS", phone);
};

const appPhoneSignIn = (data: { phone: string; code: string }) => {
  return store.dispatch("app/phoneSignIn", data);
};

const appSendEmail = (email: string) => {
  return store.dispatch("app/sendEmail", email);
};

const appEmailSignUp = (data: {
  email: string;
  code: string;
  password: string;
}) => {
  return store.dispatch("app/emailSignUp", data);
};
const appEmailSignIn = (data: { email: string; password: string }) => {
  return store.dispatch("app/emailSignIn", data);
};

const appRefreshToken = () => {
  return store.dispatch("app/refreshToken");
};

const appSendResetEmail = (email: string) => {
  return store.dispatch("app/sendResetEmail", email);
};

const appEmailReset = (data: {
  email: string;
  code: string;
  password: string;
}) => {
  return store.dispatch("app/emailReset", data);
};

export default {
  appSetLanguage,
  appCheckMobile,
  appGetOauth2Url,
  appOauth2SignIn,
  appSendSMS,
  appPhoneSignIn,
  appSendEmail,
  appEmailSignUp,
  appEmailSignIn,
  appRefreshToken,
  appSendResetEmail,
  appEmailReset,
};
