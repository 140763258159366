import Vue from "vue";
import { importAll } from "@/utils";
import "./antd";
import "./material";

const requireContext = require.context("./", true, /\.vue$/);
const components = importAll(requireContext);

for (const key in components) {
  Vue.component(components[key].options.name, components[key]);
}
