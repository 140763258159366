








import Vue from "vue";
import { mapState } from "vuex";
import { dispatch } from "@/store";

export default Vue.extend({
  computed: {
    ...mapState("app", {
      appName: "name",
    }),
  },
  methods: {
    smartScale() {
      const width = document.body.offsetWidth;
      const height = document.body.offsetHeight;
      let scaleWidth = width;
      if (width / height > 1920 / 1080) {
        scaleWidth = height * (1920 / 1080);
      }
      scaleWidth =
        scaleWidth < 1300 ? scaleWidth + (1300 - scaleWidth) : scaleWidth;
      document.documentElement.style.fontSize = scaleWidth / 19.2 + "px";
      document.body.style.fontSize = 16 + "px";
      dispatch.appCheckMobile();
    },
  },
  watch: {
    $route(to) {
      document.title = to.name ? this.$t(to.name) : this.appName;
    },
  },
  created() {
    this.smartScale();
    window.addEventListener("resize", this.smartScale);
    const language =
      localStorage.getItem("language") || navigator.language || "zh-CN";
    dispatch.appSetLanguage(language);
  },
});
