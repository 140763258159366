/* eslint-disable */
import { importAll } from "@/utils";

const imagesContext = require.context("@/assets/images", true, /\.*.$/);
const images = importAll(imagesContext);
const ossDir = "https://cdn-official.angrymiao.com/";

export interface S {
  images: any;
  oss: any;
  url: any;
}

const state: S = {
  images,
  oss: {},
  url: {
    officialWebsite: "https://www.angrymiao.com/",
  },
};

export default {
  namespaced: true,
  state,
};
