









import Vue from "vue";
import { mapState } from "vuex";

export default Vue.extend({
  name: "VHeader",
  computed: {
    ...mapState("assets", {
      images: "images",
      url: "url",
    }),
  },
});
