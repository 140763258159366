import axios, { AxiosRequestConfig } from "axios";
import { message } from "ant-design-vue";
import { auth, download } from "@/utils";
import i18n from "@/i18n";

export default ({ headers, ...options }: AxiosRequestConfig) => {
  return new Promise((resolve, reject) => {
    headers = {
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      ...headers,
    };

    const token = auth.getToken();
    if (token && !headers["No-Authorization"]) {
      headers["Authorization"] = "Bearer " + token;
    }

    if (options.params) {
      for (const key in options.params) {
        options.params[key] = options.params[key]
          ? String(options.params[key]).trim()
          : undefined;
      }
    }

    axios({
      ...options,
      timeout: 3600 * 1000,
      headers,
    })
      .then(function (response) {
        switch (response.status) {
          case 200:
          case 201:
          case 203:
          case 204:
            if (options.responseType === "blob") {
              let filename = "下载文件";
              console.log(response);
              const disposition = response.headers["content-disposition"];
              if (disposition && disposition.indexOf("attachment") !== -1) {
                const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                const matches = filenameRegex.exec(disposition);
                if (matches !== null && matches[1])
                  filename = decodeURIComponent(
                    matches[1].replace(/['"]/g, "")
                  );
              }
              download(response.data, filename);
              resolve(true);
            } else {
              resolve(response.data || true);
            }
            break;
          default:
            reject(response);
            break;
        }
      })
      .catch(function (error) {
        console.error(error);
        if (error.response) {
          switch (error.response.status) {
            case 401:
              auth.signOut();
              break;
          }
          let errorData = error.response.data.error_data;
          if (errorData?.length) {
            message.destroy();
            errorData.map(({ content }: { content: string }) => {
              if (content.includes("seconds to wait")) {
                content = content.replace("seconds to wait.", "");
                message.info(content + i18n.tc("error.seconds to wait"));
              } else {
                message.info(i18n.tc("error." + content));
              }
            });
          }
          reject(error.response.data);
        }
        reject(error);
      });
  });
};
