
























import Vue from "vue";
import { mapState } from "vuex";
import { dispatch } from "@/store";

export default Vue.extend({
  name: "VFooter",
  data() {
    return {
      year: new Date().getFullYear(),
    };
  },
  computed: {
    ...mapState("assets", {
      images: "images",
      url: "url",
    }),
    ...mapState("app", {
      language: "language",
      isCN: "isCN",
      isMobile: "isMobile",
    }),
  },
  methods: {
    setLanguage(language: string) {
      localStorage.setItem("language", language);
      dispatch.appSetLanguage(language);
    },
  },
});
