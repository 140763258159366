import Vue from "vue";
import MdButton from "vue-material/dist/components/MdButton";
import MdDialog from "vue-material/dist/components/MdDialog";
import MdField from "vue-material/dist/components/MdField";
import MdList from "vue-material/dist/components/MdList";
import MdMenu from "vue-material/dist/components/MdMenu";
import "vue-material/dist/vue-material.min.css";
import "./index.scss";

Vue.use(MdButton);
Vue.use(MdDialog);
Vue.use(MdField);
Vue.use(MdList);
Vue.use(MdMenu);
