const isTest =
  location.href.includes("test.angrymiao.com") ||
  !location.href.includes(".angrymiao.com");
const base = `https://auth${isTest ? "-test-v2" : ""}.angrymiao.com/api`;

export default {
  isTest,
  officialWebsite: `https://www${isTest ? "-test" : ""}.angrymiao.com/`, // 官网
  oauth2Url: `${base}/oauth2/url`, // 第三方登录链接获取
  oauth2SignIn: `${base}/oauth2/callback`, // 第三方登录验证

  phoneSignIn: `${base}/sign-in/phone`, // 手机短信登录
  sendSMS: `${base}/sign-in/phone-code`, // 获取短信登录验证码

  emailSignIn: `${base}/sign-in/email`, // 邮箱登录
  emailSignUp: `${base}/sign-up/email`, // 邮箱注册
  sendEmail: `${base}/sign-up/email-code`, // 获取邮箱注册验证码

  refreshToken: `${base}/jwt/refresh-token`, // 获取邮箱注册验证码

  emailReset: `${base}/password/reset-by-email`, // 邮箱密码重置
  sendResetEmail: `${base}/password/reset-code-by-email`, // 获取邮箱密码重置验证码
};
