import i18n from "@/i18n";
import { Commit } from "vuex";
import { RootState } from "@/store/modules";
import { auth } from "@/utils";
import { request, url } from "@/api";

const USERAGENT = navigator.userAgent.toUpperCase();

export interface S {
  name: string;
  language: string;
  isCN: boolean;
  isMobile: boolean;
  isInWeChat: boolean;
  isInQQ: boolean;
}

const state: S = {
  name: "Angry Miao",
  language: "en",
  isCN: false,
  isMobile: false,
  isInWeChat: USERAGENT.includes("MICROMESSENGER"),
  isInQQ: USERAGENT.includes(" QQ") && !USERAGENT.includes("MQQBROWSER"),
};

interface C {
  commit: Commit;
  state: S;
  rootState: RootState;
}

export type Oauth2Channel =
  | "google"
  | "qq"
  | "wechat"
  | "wechat_oa_userinfo"
  | "discord";

function oauth2Url(channel: Oauth2Channel) {
  const { hostname, host, pathname, search } = window.location;
  let url =
    hostname === "localhost"
      ? `https://www.angrymiao.com/`
      : `https://${host}${pathname}${search}`;
  url = url.replace(/code=.*?(&|$)/, "");
  url = url.replace(/channel=.*?(&|$)/, "");
  url = url.replace(/scope=.*?(&|$)/, "");
  url += url.includes("?") ? "&" : "?";
  url += "channel=" + channel;
  return url;
}

const actions = {
  setLanguage({ commit }: C, language: string) {
    document.documentElement.setAttribute("lang", language);
    i18n.locale = language;
    commit("SET_LANGUAGE", language);
  },
  checkMobile({ commit }: C) {
    const width = document.body.offsetWidth;
    if (width < 1000) {
      commit("SET_IS_MOBILE", true);
      return;
    } else {
      const toMatch = [
        /Android/i,
        /webOS/i,
        /iPhone/i,
        /iPad/i,
        /iPod/i,
        /BlackBerry/i,
        /Windows Phone/i,
      ];
      const isMobile = toMatch.some((toMatchItem) => {
        return navigator.userAgent.match(toMatchItem);
      });
      commit("SET_IS_MOBILE", isMobile);
    }
  },
  getOauth2Url(context: C, channel: Oauth2Channel) {
    request({
      url: url.oauth2Url,
      params: {
        channel,
        redirect_url: oauth2Url(channel),
      },
    }).then((res: any) => {
      res.url && (window.location.href = res.url);
    });
  },
  async oauth2SignIn() {
    const u = new URL(window.location.href);
    const channel = u.searchParams.get("channel");
    const code = u.searchParams.get("code");
    if (channel && code) {
      const res: any = await request({
        method: "post",
        url: url.oauth2SignIn,
        data: {
          channel,
          code,
        },
      });
      auth.signIn({
        token: res.access_token.access_token,
        refreshToken: res.refresh_token.refresh_token,
      });
    } else {
      throw new Error("没有 code");
    }
  },
  async sendSMS(context: C, phone: string) {
    const res: any = await request({
      method: "post",
      url: url.sendSMS,
      data: {
        phone: phone,
      },
    });
    return res;
  },
  async phoneSignIn(
    context: C,
    data: {
      phone: string;
      code: string;
    }
  ) {
    const res: any = await request({
      method: "post",
      url: url.phoneSignIn,
      data,
    });
    auth.signIn({
      token: res.access_token.access_token,
      refreshToken: res.refresh_token.refresh_token,
    });
  },
  async sendEmail(context: C, email: string) {
    const res: any = await request({
      method: "post",
      url: url.sendEmail,
      data: { email },
    });
    return res;
  },
  async emailSignUp(
    context: C,
    data: {
      email: string;
      code: string;
      password: string;
    }
  ) {
    const res: any = await request({
      method: "post",
      url: url.emailSignUp,
      data: data,
    });
    return res;
  },
  async emailSignIn(
    context: C,
    data: {
      email: string;
      password: string;
    }
  ) {
    const res: any = await request({
      method: "post",
      url: url.emailSignIn,
      data,
    });
    auth.signIn({
      token: res.access_token.access_token,
      refreshToken: res.refresh_token.refresh_token,
    });
  },
  async refreshToken() {
    const refreshToken = auth.getRefreshToken();
    if (refreshToken) {
      const res: any = await request({
        method: "post",
        url: url.refreshToken,
        data: { refresh_token: refreshToken },
      }).catch(() => {
        auth.signOut();
        return false;
      });
      if (res.access_token) {
        auth.signIn({
          token: res.access_token,
          refreshToken,
        });
        return true;
      } else {
        auth.signOut();
        return false;
      }
    } else {
      auth.signOut();
      return false;
    }
  },
  async sendResetEmail(context: C, email: string) {
    const res: any = await request({
      method: "post",
      url: url.sendResetEmail,
      data: { email },
    });
    return res;
  },
  async emailReset(
    context: C,
    data: {
      email: string;
      code: string;
      password: string;
    }
  ) {
    const res: any = await request({
      method: "post",
      url: url.emailReset,
      data: {
        email: data.email,
        code: data.code,
        new_password: data.password,
      },
    });
    return res;
  },
};

const mutations = {
  SET_LANGUAGE: (state: S, language: string) => {
    state.language = language;
    state.isCN = language === "zh-CN";
  },
  SET_IS_MOBILE: (state: S, isMobile: boolean) => {
    state.isMobile = isMobile;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
