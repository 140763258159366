import Vue from "vue";
import Vuex from "vuex";
import modules from "./modules";
import actions from "./actions";

Vue.use(Vuex);

export default new Vuex.Store({
  actions,
  modules,
  mutations: {},
});

export { default as dispatch } from "./dispatch";
export type { Oauth2Channel } from "@/store/modules/app";
