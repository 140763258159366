import Vue from "vue";
import VueI18n from "vue-i18n";
import en from "./en.json";
import zhCN from "./zh-CN.json";

Vue.use(VueI18n);

const messages = {
  en,
  "zh-CN": zhCN,
  "zh-TW": zhCN,
};

export default new VueI18n({
  locale: "zh-CN",
  messages,
});
