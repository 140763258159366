export { default as auth } from "./auth";

export const deepCopy = (object: object) => JSON.parse(JSON.stringify(object));

export const download = (fileOrUrl: File | string, fileName?: string) => {
  let url = "";
  if (typeof fileOrUrl !== "string") {
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(fileOrUrl, fileName);
    } else {
      url = URL.createObjectURL(fileOrUrl);
    }
  } else {
    url = fileOrUrl;
  }
  const a = document.createElement("a");
  a.href = url;
  a.target = "_blank";
  a.download = fileName || url.substring(url.lastIndexOf("/") + 1);
  a.click();
};

export const imagesLoader = (images: Array<string>, callback: Function) => {
  const total = images.length;
  let completed = 0;
  images.map((src) => {
    const image = new Image();
    image.src = src;
    image.onload = () => {
      callback && callback(++completed / total === 1);
    };
    image.onerror = () => {
      console.error("TOOL Error imagesLoader:" + src);
      callback && callback(++completed / total === 1);
    };
  });
};

export const importAll = (requireContext: any, exclude?: Array<string>) => {
  // https://webpack.js.org/guides/dependency-management/#requirecontext
  // you do not need `import item from './items/item'`
  // it will auto require all item from items file
  return requireContext.keys().reduce((all: any, path: string) => {
    const name = path.replace(/^.*\/(.*)\.\w+$/, "$1");
    if (!(exclude && exclude.includes(name))) {
      const value = requireContext(path);
      all[name] = value.default || value;
    }
    return all;
  }, {});
};
