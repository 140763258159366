



import Vue from "vue";
import { dispatch } from "@/store";
import { url } from "@/api";

export default Vue.extend({
  mounted() {
    dispatch.appRefreshToken().then((refreshed) => {
      const { redirect } = this.$route.query;
      if (refreshed) {
        window.location.href = redirect
          ? redirect.toString()
          : url.officialWebsite;
      } else {
        window.location.href =
          "/?redirect=" + encodeURIComponent((redirect || "").toString());
      }
    });
  },
});
