










































































































import Vue from "vue";
import { mapState } from "vuex";
import { dispatch, Oauth2Channel } from "@/store";
import { url } from "@/api";

export default Vue.extend({
  data() {
    return {
      isTest: url.isTest,
      loading: false,
      time: 0,
      phone: "",
      code: "",
      email: "",
      password: "",
      weChatSignInQRVisible: false,
    };
  },
  computed: {
    ...mapState("app", {
      isCN: "isCN",
      isMobile: "isMobile",
      isInWeChat: "isInWeChat",
      isInQQ: "isInQQ",
    }),
    ...mapState("account", {
      privateKey: "privateKey",
    }),
    ...mapState("assets", {
      images: "images",
    }),
    phoneHelp(): string {
      if (this.phone && !/^1[3456789]\d{9}$/.test(this.phone)) {
        return "" + this.$t("inputError") + this.$t("phone");
      }
      return "";
    },
    sendText(): string {
      if (this.time) {
        return "" + this.time + this.$t("resend");
      }
      return "" + this.$t("sendCode");
    },
    emailHelp(): string {
      if (
        this.email &&
        !/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/.test(this.email)
      ) {
        return "" + this.$t("inputError") + this.$t("email");
      }
      return "";
    },
  },
  mounted() {
    this.checkSignIn();
  },
  methods: {
    checkSignIn() {
      const { channel, code } = this.$route.query;
      if (code && channel) {
        this.loading = true;
        dispatch
          .appOauth2SignIn()
          .then(this.afterSignIn)
          .catch(() => {
            this.loading = false;
          });
      }
    },
    onOAuth2Click(channel: Oauth2Channel) {
      dispatch.appGetOauth2Url(channel).then((res) => {
        if (res.data.url) {
          window.location.href = res.data.url;
        }
      });
    },
    onWeChatClick() {
      if (this.isInWeChat) {
        dispatch.appGetOauth2Url("wechat_oa_userinfo").then((res) => {
          if (res.data.url) {
            window.location.href = res.data.url;
          }
        });
      } else if (this.isMobile) {
        this.$message.info(this.$t("openInWeChat") + "");
        return;
      } else {
        dispatch.appGetOauth2Url("wechat").then((res) => {
          if (res.data.url) {
            window.location.href = res.data.url;
          }
        });
      }
    },
    onSendCode() {
      const { phone, time } = this;
      if (this.phoneHelp) {
        return false;
      } else if (!time) {
        dispatch
          .appSendSMS(phone)
          .then(() => {
            this.$message.info("" + this.$t("sendCaptchaCodeSuccess"));
            this.time = 59;
            const timer = setInterval(() => {
              this.time = this.time - 1;
              if (this.time <= 1) {
                clearInterval(timer);
                this.time = 0;
              }
            }, 1000);
          })
          .catch((error) => {
            console.error(error);
          });
      }
    },
    onPhoneSignIn() {
      if (!this.phone || this.phoneHelp) {
        this.$message.info("" + this.$t("inputError") + this.$t("phone"));
        return false;
      } else if (!this.code) {
        this.$message.info("" + this.$t("inputError") + this.$t("CAPTCHA"));
        return false;
      }
      this.loading = true;
      dispatch
        .appPhoneSignIn({
          phone: this.phone,
          code: this.code.trim(),
        })
        .then(this.afterSignIn)
        .catch(() => {
          this.loading = false;
        });
    },
    onEmailSignIn() {
      if (!this.email || this.emailHelp) {
        this.$message.info("" + this.$t("inputError") + this.$t("email"));
        return false;
      } else if (!this.password) {
        this.$message.info("" + this.$t("inputError") + this.$t("password"));
        return false;
      }
      this.loading = true;
      dispatch
        .appEmailSignIn({
          email: this.email,
          password: this.password,
        })
        .then(this.afterSignIn)
        .catch(() => {
          this.loading = false;
        });
    },
    afterSignIn() {
      this.loading = false;
      const { redirect } = this.$route.query;
      this.$message.info("" + this.$t("signInSuccess"));
      setTimeout(() => {
        debugger;
        window.location.href = redirect
          ? redirect.toString()
          : url.officialWebsite;
      }, 1000);
    },
    onSignUp() {
      this.$router.push({
        path: this.$paths.signUp,
        query: this.$route.query,
      });
    },
    onReset() {
      this.$router.push({
        path: this.$paths.reset,
        query: this.$route.query,
      });
    },
  },
});
